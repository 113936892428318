import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import PageMetaData from 'components/PageMetaData';
import BreadCrumbs from 'components/BreadCrumbs';
import {logEvent} from 'utility';
import ApprovalDelegateDialog from 'components/Settings/ApprovalDelegateDialog';
import {useSelector} from 'react-redux';
import './CustomerAdmin/customer-admin.less';

export default function SettingsLanding () {
    const history = useHistory();
    const [showOutOfOfficeDialog, setShowOutOfOfficeDialog] = useState(false);
    const [delegate, setDelegate] = useState(false);
    const user = useSelector((state) => state.user);
    const sections = [
        {
            id: 1,
            title: 'Personal',
            description: 'Change password, username and phone',
            imageUrl: '/images/icn-settings-user-management.png',
            path: '/settings/personal',
            display: true,
        },
        {
            id: 2,
            title: 'Notifications',
            description: 'Set up email and text preferences',
            imageUrl: '/images/icn-settings-notifications.png',
            path: '/settings/notifications',
            display: true,
        },
        {
            id: 3,
            title: 'Addresses',
            description: 'Edit, add or remove shipping addresses',
            imageUrl: '/images/icn-settings-addresses.png',
            path: '/settings/addresses',
            display: (user.info.companyTypeId === 21), // isPersonal,
        },
        {
            id: 4,
            title: 'Payment Methods',
            description: 'Add, edit and delete debit and credit cards numbers',
            imageUrl: '/images/icn-settings-payment-methods.png',
            path: '/settings/payment',
            display: (!user.settings.isProgramAccountMember && !user.settings.siteLvlCC),
        },
        {
            id: 5,
            title: 'Out of Office',
            description: 'Schedule your approval queue to be delegated while you’re out of office',
            imageUrl: '/images/icn-settings-out-of-office.png',
            path: null, 
            display: user.settings.approver,// !isPersonal
        },
        
    ]
    useEffect(() => {   
        getApprovalDelegate();
    }, []);
    
    const getApprovalDelegate = () => {
        axios.get('/SettingsService/api/v1/approvalDelegate').then(x => {
            let delegateData = x.data || {};
            setDelegate(delegateData);
            setShowOutOfOfficeDialog(false);
        });
    }
    const onSectionClick = (section) => {
        logEvent('Customer Settings Views', {
            'UserId': user.info.contactId,
            'Settings Option': section.title,
        });
        if (section.path)
            history.push(section.path);
        else
            setShowOutOfOfficeDialog(true);
    }
    return (
        <div className="customer-settings-landing">
            <PageMetaData
                title="My Account" 
                pageType="other" 
                trackAnalytics={true} />

            <BreadCrumbs />
            <div className="customer-admin-body">
                {
                    sections.map(x => {
                        return x.display && <div className="single-section" onClick={() => {
                            onSectionClick(x) 
                        }}> 
                            <img
                                src={x.imageUrl}
                                className="icon"
                                alt={x.title}
                            />
                            <div className="section-body">
                                <div className="section-title">{x.title}</div>
                                <div>{x.description}</div>
                            </div>
                            <span className="section-chevron"><i className="fa fa-chevron-right" /></span>
                        </div>
                    })
                }
            </div>
            {showOutOfOfficeDialog ?
                <ApprovalDelegateDialog delegate={_.cloneDeep(delegate)} onSubmit={getApprovalDelegate} onCancel={() => setShowOutOfOfficeDialog(false)} /> : null}
        </div>
    );    
}
