import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types'
import React from 'react';
import {connect} from 'react-redux';

import {Checkbox, TextFieldSelect} from '@partssourceinc/react-ui-core';
import PopupAdmin from '../PopupAdmin';
import Datepicker from 'components/Datepicker';
import {Switch} from '@partssourceinc/react-ui-core';
import * as UserStore from 'stores/User';

@connect((state) => ({user: state.user}))
export default class ApprovalDelegateDialog extends React.Component {
    static propTypes = {
        delegate: PropTypes.object.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        user: UserStore.StateShape,
    };

    constructor(props) {
        super(props);

        this.state = {
            delegate: props.delegate.id ? props.delegate : {
                sendEmails: true,
                active: true,
                startDate: null,
                endDate: null,
                delegateId: null,
            },
            saving: false,
            contacts: [],
        }
    }

    componentWillMount() {
        const {user: {info: {contactId}}} = this.props;
        axios.get('/ShoppingService/api/v1/company/contacts').then(x => {
            let contacts = x.data.filter(x => x.contactId !== contactId);
            this.setState({contacts});
        });
    }

    onOutOfOfficeFieldChange(e, data) {
        let {delegate} = this.state;
        if (data.id === 'startDate' || data.id === 'endDate') {
            let newDate = moment(e).format('MM/DD/YY');
            delegate[data.id] = newDate === 'Invalid date' ? null : newDate;
        } else if (data.id === 'sendEmails') {
            delegate[data.id] = data.checked;
        } else {
            delegate[data.id] = data.value;
        }

        this.setState({delegate});
    }

    onActiveFlagChange(e, result) {
        let {delegate} = this.state;
        delegate.active = result.selection === 'Left' ? true : false;
        this.setState({delegate});
    }

    onSaveOutOfOffice() {
        const {delegate} = this.state;
        const {onSubmit} = this.props;
        
        this.setState({saving: true});
        axios.post('SettingsService/api/v1/approvalDelegate', delegate).then(() => {
            onSubmit();
            this.setState({saving: false});
        });
    }

    render() {
        const {onCancel} = this.props;
        const {saving, contacts, delegate} = this.state;
        const switchToggleItems = [
            {text: 'On', value: true, selection: 'Left'},
            {text: 'Off', value: false, selection: 'Right'},
        ];

        const allowSave = delegate.startDate && delegate.endDate && delegate.delegateId && (moment(delegate.startDate) <= moment(delegate.endDate));
        return (
            <PopupAdmin confirmText="Apply"
                cancelText="Cancel"
                show={true}
                onConfirm={::this.onSaveOutOfOffice}
                onCancel={onCancel}
                className="out-of-office-dialog"
                disableConfirm={!allowSave}
                loading={saving}>
                <h1>Approval Delegation
                    <Switch id="active" items={switchToggleItems} style={{marginLeft: '20px'}} selected={delegate.active ? 'Left' : 'Right'} onChange={::this.onActiveFlagChange} />
                </h1>
                <div className="requester-row">
                    <TextFieldSelect
                        keyField="contactId"
                        valueField="fullName"
                        onSelect={contact => this.onOutOfOfficeFieldChange(null, {id: 'delegateId', value: contact.contactId})}
                        listItems={contacts}
                        label="Delegate"
                        placeHolder="Delegate"
                        selectedItem={contacts.find(x => x.contactId === delegate.delegateId)}
                        disableClear={true}
                    />
                </div>
                <div className="date-row">
                    <Datepicker placeholder="From" id="startDate" onChange={::this.onOutOfOfficeFieldChange} value={delegate.startDate && new Date(delegate.startDate)} minDate={moment().toDate()} />
                    <Datepicker placeholder="To" id="endDate" onChange={::this.onOutOfOfficeFieldChange} value={delegate.endDate && new Date(delegate.endDate)} minDate={moment().toDate()} />
                </div>
                <div className="notification-row">
                    <Checkbox id="sendEmails" checked={delegate.sendEmails} label="Continue to send me email notifications during this time"
                        onChange={(data) => this.onOutOfOfficeFieldChange(null, data)} />
                </div>
            </PopupAdmin>
        );
    }
}
